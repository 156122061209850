import React from 'react';
interface Props {
  width?: number;
  height?: number;
}

export const Loading: React.FC<Props> = ({ width = 30, height = 30 }) => (
  <div
    style={{ width: `${width}px`, height: `${height}px` }}
    className="animate-spin rounded-[50%] border-[4px] border-solid border-[#DADADA] border-t-black-500"
  />
);
