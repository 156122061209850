/* eslint @typescript-eslint/no-explicit-any: 0 */
import { z } from 'zod';

// login
export interface PostSessionRequest {
  email: string;
  password: string;
  force?: boolean;
  serviceName: string;
}

export const PostSessionRequest = {
  fromJson: (object: any) => ({
    email: String(object?.email ?? ''),
    password: String(object?.password ?? ''),
    force: object?.force != null ? Boolean(object.force) : undefined,
    serviceName: String(object?.serviceName ?? ''),
  }),
};

export const postSessionBaseSchema = z.object({
  type: z.union([z.literal('success'), z.literal('redirect'), z.literal('fail')]),
});

export const postSessionSuccessSchema = postSessionBaseSchema.extend({
  type: z.literal('success'),
  token: z.string(),
  tokenExpireAt: z.number(),
  createdAt: z.number(),
  user: z.object({
    id: z.number(),
    identifierHash: z.string(),
  }),
});
type PostSessionSuccessResponse = z.infer<typeof postSessionSuccessSchema>;

export const postSessionRedirectSchema = postSessionBaseSchema.extend({
  type: z.literal('redirect'),
  url: z.string(),
});
type PostSessionRedirectResponse = z.infer<typeof postSessionRedirectSchema>;

const messageSchema = z.object({
  base: z.string(),
});
export const postSessionFailSchema = postSessionBaseSchema.extend({
  type: z.literal('fail'),
  error: z.object({
    code: z.number().optional(),
    messages: z.array(messageSchema).optional(),
  }),
});
type PostSessionFailResponse = z.infer<typeof postSessionFailSchema>;

export type PostSessionResponse = PostSessionSuccessResponse | PostSessionRedirectResponse | PostSessionFailResponse;

// me
export interface PutSessionResponse {
  token: string;
  tokenExpireAt: number;
}

export const PutSessionResponse = {
  fromJson: (object: any): PutSessionResponse => {
    return {
      token: String(object?.token ?? ''),
      tokenExpireAt: Number(object?.tokenExpireAt ?? 0),
    };
  },
};
