/* eslint @typescript-eslint/no-explicit-any: 0 */

import { create } from 'zustand';
import { snakeToCamel } from '~/utils/convertCase';
import { Option } from './option';
import { ErrorType, RequestError } from './RequestError';
import { toRequestInit, parseResponseJson } from './utils';

const request = async (baseUrl: string, path: string, init: RequestInit) => {
  const endpoint = `${baseUrl}${path}`;

  let response: Response;
  try {
    response = await fetch(endpoint, init);
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.Canceled,
        innerError: error,
      });
    }
    throw new RequestError({
      endpoint,
      method: init.method ?? 'GET',
      body: init.body,
      errorType: ErrorType.NetworkError,
      innerError: error,
    });
  }

  if (!response.ok) {
    const body = await parseResponseJson(response);

    if (response.status >= 400 && response.status < 500) {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.ClientError,
        status: response.status,
        errorMessage: body?.message,
      });
    }
    if (response.status >= 500) {
      throw new RequestError({
        endpoint,
        method: init.method ?? 'GET',
        body: init.body,
        errorType: ErrorType.ServerError,
        status: response.status,
        errorMessage: body?.message,
      });
    }
  }
  const json = await parseResponseJson(response);
  return snakeToCamel(json ?? {}) as any;
};

export const pvApiClient = (option?: Option) => ({
  get: (path: string, init?: RequestInit) => request(getBaseURL(), path, toRequestInit('GET', init, option)),
  post: (path: string, data: unknown, init?: RequestInit) =>
    request(getBaseURL(), path, toRequestInit('POST', init, option, data)),
  put: (path: string, data: unknown, init?: RequestInit) =>
    request(getBaseURL(), path, toRequestInit('PUT', init, option, data)),
  patch: (path: string, data: unknown, init?: RequestInit) =>
    request(getBaseURL(), path, toRequestInit('PATCH', init, option, data)),
  delete: (path: string, data?: unknown, init?: RequestInit) =>
    request(getBaseURL(), path, toRequestInit('DELETE', init, option, data)),
});

function getBaseURL(): string {
  const { baseURL } = usePvApiClientStore.getState();

  if (!baseURL) {
    throw new Error('`baseURL` must be non-null');
  }
  return baseURL;
}

type PvApiClientStore = {
  baseURL?: string;
  setBaseURL: (baseURL: string) => void;
  resetBaseURL: () => void;
};

export const usePvApiClientStore = create<PvApiClientStore>(set => ({
  baseURL: process.env.NEXT_PUBLIC_PV_API_BASE_URL,
  setBaseURL: (baseURL: string) =>
    set(state => ({
      ...state,
      baseURL,
    })),
  resetBaseURL: () =>
    set(state => ({
      ...state,
      baseURL: process.env.NEXT_PUBLIC_PV_API_BASE_URL,
    })),
}));
